// Vendors
import React from 'react';
// Components
import { BannerComponent } from '@openbank/cb-ui-commons';
// Constants
import { APP_BANNER_DEFAULT_PROPS, BUTTON_CONFIGURATION, CTA_URL } from './constants/banner.constants';
// Styles
import { BannerComponentStyled } from './banner.component.styled';
import { useNavigate } from 'react-router-dom';

const AppBannerComponent = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <BannerComponentStyled {...APP_BANNER_DEFAULT_PROPS}>
      <BannerComponent
        {...APP_BANNER_DEFAULT_PROPS}
        buttonConfiguration={{
          ...BUTTON_CONFIGURATION,
          onClick: () => navigate(CTA_URL),
        }}
      />
    </BannerComponentStyled>
  );
};

export default AppBannerComponent;
