// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';

const CTA_URL = '/financing/fast-cash';

const BUTTON_CONFIGURATION = {
  children: 'Geld auszahlen',
  onClick: (): any => window.open(CTA_URL),
  size: buttonConstants.SIZES.SMALL,
};

const APP_BANNER_DEFAULT_PROPS = {
  backgroundImage: `${window.ASSETS_SERVER_PATH}banners/202409_zinia_amz_banner_desktop_${window.devicePixelRatio}x.jpg`,
  title: 'Du kannst dein Saldo von einer anderen Karte übertragen. Probiere es jetzt aus!',
  buttonConfiguration: BUTTON_CONFIGURATION,
};

export { APP_BANNER_DEFAULT_PROPS, BUTTON_CONFIGURATION, CTA_URL };
